import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Avatar } from '@mui/material';

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <Avatar alt="Remy Sharp" src={row.appIcon} variant="rounded" />
        </TableCell>
        {/* <TableCell align="left">
          <Typography variant="body2">{row.appName}</Typography>
        </TableCell> */}
        <TableCell align="right">{row.appID}</TableCell>
        <TableCell align="right">{row.conversions[0].campaignID}</TableCell>
        <TableCell align="right">{row.adGroupId}</TableCell>
        <TableCell align="right">{row.conversions.length}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Conversions
              </Typography>
              <Typography variant="caption" gutterBottom component="div">
                <b>Summary: </b>{row.keywordIdSummary}
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell><b>date</b></TableCell>
                    <TableCell align="right"><b>purchase</b></TableCell>
                    <TableCell align="right"><b>countryOrRegion</b></TableCell>
                    <TableCell align="right"><b>conversionType</b></TableCell>
                    <TableCell align="right"><b>orgId</b></TableCell>
                    <TableCell align="right"><b>attribution</b></TableCell>
                    <TableCell align="right"><b>keywordId</b></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.conversions.map((row) => (
                    <TableRow key={row.date}>
                      <TableCell component="th" scope="row">
                        {row.date}
                      </TableCell>
                      <TableCell align="right">{row.purchase}</TableCell>
                      <TableCell align="right">{row.countryOrRegion}</TableCell>
                      <TableCell align="right">{row.conversionType}</TableCell>
                      <TableCell align="right">{row.orgId}</TableCell>
                      <TableCell align="right">{row.attribution}</TableCell>
                      <TableCell align="right">{row.keywordId}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function TrackingTable(props) {
  const {rows} = props;
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell align="right"></TableCell>
            {/* <TableCell align="left">appName</TableCell> */}
            <TableCell align="right"><b>appID</b></TableCell>
            <TableCell align="right"><b>campaignID</b></TableCell>
            <TableCell align="right"><b>adGroupId</b></TableCell>
            <TableCell align="right"><b>total</b></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <Row key={row.name} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}