import React from "react";
import axios from "axios";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import {
	Avatar,
	Backdrop,
	CircularProgress,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Typography,
	Button,
	Box,
	TextField,
} from "@mui/material";

const mdTheme = createTheme();

const columns = [
	{ id: "appIcon", label: "", minWidth: 50 },
	{ id: "appName", label: "appName", minWidth: 100 },
	{ id: "appID", label: "appID", minWidth: 100 },
	{
		id: "purchase",
		label: "purchase",
		minWidth: 100,
		align: "left",
		format: (value) => value.toLocaleString("en-US"),
	},
	{ id: "countryOrRegion", label: "countryOrRegion", minWidth: 100, align: "center" },
	{
		id: "createdAt",
		label: "time",
		minWidth: 100,
		align: "center",
		format: (value) => value.toLocaleString("en-US"),
	},
];

const LogAndroid = () => {
	const [page, setPage] = React.useState(0);
	const [rows, setRows] = React.useState([]);
	const [statistic, setStatistic] = React.useState([]);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const [selectedAppID, setSelectedAppID] = React.useState("");
	const [loading, setLoading] = React.useState(false);
	const [summary, setSummary] = React.useState("");
	const [subDetail, setSubDetail] = React.useState([]);
	const [exportState, setExportState] = React.useState(false);
	const [range, setRange] = React.useState(1);
	const [startDate, setStartDate] = React.useState("");
	const [endDate, setEndDate] = React.useState("");

	React.useEffect(() => {
		setLoading(true);
		let from, to;
		const now = new Date();

		switch (range) {
			case 0:
				from = new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime();
				to = now.getTime();
				// from = now.getTime() - 24 * 60 * 60 * 1000;
				break;
			case 3:
				const threeDaysAgo = now.getTime() - 3 * 24 * 3600 * 1000;
				from = new Date(threeDaysAgo).setHours(0, 0, 0, 0);
				to = now.setHours(23, 59, 59, 999);
				break;
			case 6:
				let startingWeekday = 0;
				let startingWeekDate = now.getDate() - now.getDay() + (now.getDay() === 0 ? -6 : startingWeekday);
				from = new Date(now.getFullYear(), now.getMonth(), startingWeekDate).getTime();
				to = from + 6 * 24 * 3600 * 1000;
				break;
			case 7:
				let sevenDaysAgo = new Date(now.getTime() - 7 * 24 * 3600 * 1000);
				from = new Date(sevenDaysAgo.getFullYear(), sevenDaysAgo.getMonth(), sevenDaysAgo.getDate()).getTime();
				to = new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime();
				break;
			case 30:
				let thirtyDaysAgo = new Date(now.getTime() - 30 * 24 * 3600 * 1000);
				from = new Date(thirtyDaysAgo.getFullYear(), thirtyDaysAgo.getMonth(), thirtyDaysAgo.getDate()).getTime();
				to = new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime();
				break;
			case 29:
				from = new Date(now.getFullYear(), now.getMonth(), 1).getTime();
				to = new Date(now.getFullYear(), now.getMonth() + 1, 0).getTime();
				break;
			case 31:
				let startTime = new Date(now.getFullYear(), now.getMonth() - 1, 1).getTime();
				let endTime = new Date(now.getFullYear(), now.getMonth(), 0).getTime();
				from = new Date(startTime).getTime();
				to = new Date(endTime).getTime();
				break;
			default:
				from = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1).setHours(0, 0, 0, 0);
				to = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1).setHours(23, 59, 59, 999);
				break;
		}

		axios.get(`${window.domain}/api/android-logs?from=${from}&to=${to}&appID=${selectedAppID}`).then(async (response) => {
			const data = response.data;
			setRows(data.data);
			setStatistic(data.statistic);
			setSummary(data.summary);
			setSubDetail(data.subDetail);
			setLoading(false);
		});
	}, [range, selectedAppID]);

	const handleSelectRange = (event) => {
		setRange(event.target.value);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	const handleStartDateChange = (event) => {
		setStartDate(event.target.value);
	};

	const handleEndDateChange = (event) => {
		setEndDate(event.target.value);
	};

	const handleFilterDate = async () => {
		setLoading(true);
		try {
			const from = new Date(startDate).setHours(0, 0, 0, 0);
			const to = new Date(endDate).setHours(23, 59, 59, 999);

			const res = await axios.get(`${window.domain}/api/android-logs?from=${from}&to=${to}&appID=${selectedAppID}`);
			const data = res.data;
			setRows(data.data);
			setStatistic(data.statistic);
			setSummary(data.summary);
			setSubDetail(data.subDetail);
			setLoading(false);
		} catch (error) {
			console.log(error.message);
		} finally {
			setLoading(false);
		}
	};

	const handleDownloadCSV = async () => {
		try {
			setExportState(true);

			const res = await axios.post(`${window.domain}/api/download-csv-other`, { data: rows }, { responseType: "blob" });
			const url = window.URL.createObjectURL(new Blob([res.data], { type: "text/csv" }));
			const a = document.createElement("a");
			a.href = url;
			a.download = "Other Logs.csv";
			a.style.display = "none";
			document.body.appendChild(a);
			a.click();

			window.URL.revokeObjectURL(url);
			document.body.removeChild(a);
			setExportState(false);
		} catch (error) {
			console.log(error.message);
		}
	};

	const handleDownloadAllRecord = async () => {
		try {
			setExportState(true);

			const res = await axios.post(`${window.domain}/api/android-download-all-other?appId=${selectedAppID}`, {
				responseType: "blob",
			});
			const url = window.URL.createObjectURL(new Blob([res.data], { type: "text/csv" }));
			const a = document.createElement("a");
			a.href = url;
			a.download = "All-Other-Logs.csv";
			a.style.display = "none";
			document.body.appendChild(a);
			a.click();

			window.URL.revokeObjectURL(url);
			document.body.removeChild(a);
			setExportState(false);
		} catch (error) {
			console.log(error.message);
		}
	};

	return (
		<ThemeProvider theme={mdTheme}>
			<Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
				<Grid container spacing={3}>
					<Grid item xs={4}>
						<Typography variant="caption" color="#6200EE" sx={{ m: 1 }}>
							STATISTICS
						</Typography>
						<Paper sx={{ width: "100%", overflow: "hidden" }}>
							<FormControl sx={{ m: 1, width: "95%" }} size="small">
								<InputLabel id="demo-select-small">Range</InputLabel>
								<Select
									labelId="demo-select-small"
									id="demo-select-small"
									value={range}
									label="Range"
									onChange={handleSelectRange}
								>
									<MenuItem value={0}>Today</MenuItem>
									<MenuItem value={1}>Yesterday</MenuItem>
									<MenuItem value={3}>Last 3 days</MenuItem>
									<MenuItem value={6}>This week</MenuItem>
									<MenuItem value={7}>Last 7 days</MenuItem>
									<MenuItem value={30}>Last 30 days</MenuItem>
									<MenuItem value={29}>Current month</MenuItem>
									<MenuItem value={31}>Last month</MenuItem>
								</Select>
								<Box margin="1rem 0rem" alignItems="center">
									<TextField
										id="start-date"
										label="Start Date"
										type="date"
										size="small"
										value={startDate}
										onChange={handleStartDateChange}
										InputLabelProps={{
											shrink: true,
										}}
									/>
									<TextField
										id="end-date"
										label="End Date"
										type="date"
										size="small"
										value={endDate}
										onChange={handleEndDateChange}
										InputLabelProps={{
											shrink: true,
										}}
									/>
								</Box>
								<Button variant="contained" color="secondary" onClick={handleFilterDate}>
									Filter
								</Button>
								<Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
									<CircularProgress color="inherit" />
								</Backdrop>
							</FormControl>
							<TableContainer sx={{ maxHeight: "800" }}>
								<Table stickyHeader aria-label="sticky table">
									<TableHead>
										<TableRow>
											<TableCell key="app_icon" align="left"></TableCell>
											<TableCell key="app_name" align="left">
												appName
											</TableCell>
											<TableCell key="count" align="left">
												count
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{statistic.map((row) => (
											<TableRow
												hover
												sx={{ background: selectedAppID === row.appID ? "#e0e0e0" : "#ffffff" }}
												role="checkbox"
												tabIndex={-1}
												key={"statistic" + row.appID}
											>
												<TableCell key="app_icon_item" align="left" onClick={() => setSelectedAppID(row.appID)}>
													<Avatar
														alt="appIcon"
														src={
															row.appIcon !== ""
																? row.appIcon
																: "https://redbarnstudios206461841.files.wordpress.com/2021/07/a.jpg"
														}
														variant="rounded"
													/>
												</TableCell>
												<TableCell key="app_name_item" align="left" onClick={() => setSelectedAppID(row.appID)}>
													{row.appName}
												</TableCell>
												<TableCell key="count_item" align="left" onClick={() => setSelectedAppID(row.appID)}>
													{row.count}
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</Paper>
					</Grid>
					<Grid item xs={8}>
						<Paper sx={{ width: "100%", overflow: "hidden", padding: 1 }}>
							<Typography variant="caption" color="#000">
								Country summary
							</Typography>
							<Typography variant="h6" color="#000">
								{summary}
							</Typography>
						</Paper>
						<Paper sx={{ width: "100%", overflow: "hidden", padding: 1 }}>
							{selectedAppID === "" ? null : (
								<>
									<Typography variant="caption" color="#000">
										Subcription detail:
									</Typography>
									{subDetail.map((item) => (
										<Typography variant="h6" color="#000">
											{item.purchase}: {item.count}
										</Typography>
									))}
								</>
							)}
						</Paper>
						<Paper sx={{ width: "100%", overflow: "hidden" }}>
							<TableContainer sx={{ maxHeight: 800 }}>
								<Table stickyHeader aria-label="sticky table">
									<TableHead>
										<TableRow>
											{columns.map((column) => (
												<TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
													{column.label}
												</TableCell>
											))}
										</TableRow>
									</TableHead>
									<TableBody>
										{rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
											return (
												<TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
													{columns.map((column) => {
														const value = row[column.id];
														var cell;
														if (column.id === "appIcon")
															cell = (
																<TableCell>
																	<Avatar alt="appIcon" src={value} variant="rounded" />
																</TableCell>
															);
														else
															cell = (
																<TableCell key={column.id} align={column.align}>
																	{column.format && typeof value === "number" ? column.format(value) : value}
																</TableCell>
															);
														return cell;
													})}
												</TableRow>
											);
										})}
									</TableBody>
								</Table>
							</TableContainer>
							<TablePagination
								rowsPerPageOptions={[10, 25, 100]}
								component="div"
								count={rows.length}
								rowsPerPage={rowsPerPage}
								page={page}
								onPageChange={handleChangePage}
								onRowsPerPageChange={handleChangeRowsPerPage}
							/>
						</Paper>
						<Paper sx={{ width: "100%", overflow: "hidden", padding: 2, textAlign: "center" }}>
							<Button
								variant="contained"
								sx={{ bgcolor: "green" }}
								onClick={() => {
									handleDownloadCSV();
								}}
							>
								Download CSV
							</Button>

							<Button
								variant="contained"
								disabled={exportState}
								sx={{ margin: 2, bgcolor: "orange" }}
								onClick={() => {
									handleDownloadAllRecord();
								}}
							>
								Download Records of All Times
							</Button>
						</Paper>
					</Grid>
				</Grid>
			</Container>
		</ThemeProvider>
	);
};

export default LogAndroid;
