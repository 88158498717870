import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import Login from "./components/Login";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";

// window.domain = "http://localhost:7337";
window.domain = "https://appletracking.addonsmaster.com";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<Routes>
				<Route index element={<App />} />
				<Route path="login" element={<Login />} />
			</Routes>
		</BrowserRouter>
	</React.StrictMode>
);

reportWebVitals();
