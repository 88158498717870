import * as React from 'react';
import Typography from '@mui/material/Typography';
import Title from './Title';

export default function Conversion(props) {
  const { total } = props;
  // const today = new Date().toLocaleString();
  return (
    <React.Fragment>
      {/* <Typography color="primary" component="p" variant="h5">
            Summary
        </Typography> */}
      <Title>Conversion</Title>
      <Typography component="p" variant="h5">
        {total}
      </Typography>
      {/* <Typography color="text.secondary" sx={{ flex: 1 }}>
        {"on " + today}
      </Typography> */}
    </React.Fragment>
  );
}