import React, { useState } from "react";
import { TextField, Button, Grid, Typography, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import bcrypt from "bcryptjs";

const SECRET_TOKEN =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI4ODg4IiwibmFtZSI6ImZveGNvZGUiLCJpYXQiOjEyMzQ1Njc4OX0.hIBlCAluXL8BxgjHTClKRFKEALKvds8SdZgZSCU_KMg";
const users = { username: "admin", password: "$2a$10$4vznVHCWrRDPw/IaBcbaS.F8aRZTKppJPsdAUFMAw96DdoXi.2kZO" };

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const validUser = users.username === username ? true : false;

    const isPasswordValid = await bcrypt.compare(password, users.password);
    if (validUser) {
      if (isPasswordValid) {
        localStorage.setItem("authenticated", SECRET_TOKEN);
        navigate("/");
      }
    }
  };

  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
      <Paper style={{ padding: 16, maxWidth: 400 }}>
        <Typography variant="h5" style={{ marginBottom: 16, textAlign: "center" }}>
          Login
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <TextField
                label="Username"
                variant="outlined"
                style={{ marginBottom: 16 }}
                value={username}
                onChange={handleUsernameChange}
              />
            </Grid>
            <Grid item>
              <TextField
                label="Password"
                variant="outlined"
                type="password"
                style={{ marginBottom: 16 }}
                value={password}
                onChange={handlePasswordChange}
              />
            </Grid>
            <Grid item container justifyContent="center">
              <Button type="submit" variant="contained" color="primary" style={{ textAlign: "center" }}>
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </div>
  );
};

export default Login;
