import * as React from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import AppleIcon from "@mui/icons-material/Apple";
import OtherIcon from "@mui/icons-material/NetworkCell";
import AdbIcon from "@mui/icons-material/Adb";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import OtherAds from "./components/OtherAds";
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import SearchAdsAdvance from "./components/SearchAdsAdvance";
import SearchAdsBasic from "./components/SearchAdsBasic";
import LogAndroid from "./components/LogAndroid";
import { useNavigate } from "react-router-dom";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(["width", "margin"], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
	"& .MuiDrawer-paper": {
		position: "relative",
		whiteSpace: "nowrap",
		width: drawerWidth,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		boxSizing: "border-box",
		...(!open && {
			overflowX: "hidden",
			transition: theme.transitions.create("width", {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			width: theme.spacing(7),
			[theme.breakpoints.up("sm")]: {
				width: theme.spacing(9),
			},
		}),
	},
}));

const mdTheme = createTheme();
const SECRET_TOKEN =
	"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI4ODg4IiwibmFtZSI6ImZveGNvZGUiLCJpYXQiOjEyMzQ1Njc4OX0.hIBlCAluXL8BxgjHTClKRFKEALKvds8SdZgZSCU_KMg";

function App() {
	const navigate = useNavigate();
	const [open, setOpen] = React.useState(true);
	const [selectedItem, setSelectedItem] = React.useState("advance");

	React.useEffect(() => {
		const authenToken = localStorage.getItem("authenticated");
		if (!authenToken || authenToken !== SECRET_TOKEN) {
			navigate("/login");
		}
	}, []);

	const toggleDrawer = () => {
		setOpen(!open);
	};

	return (
		<ThemeProvider theme={mdTheme}>
			<Box sx={{ display: "flex" }}>
				<CssBaseline />
				<AppBar position="absolute" open={open}>
					<Toolbar sx={{ pr: "24px" }}>
						<IconButton
							edge="start"
							color="inherit"
							aria-label="open drawer"
							onClick={toggleDrawer}
							sx={{
								marginRight: "36px",
								...(open && { display: "none" }),
							}}
						>
							<MenuIcon />
						</IconButton>
						<Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
							Foxads tracking
						</Typography>
					</Toolbar>
				</AppBar>
				<Drawer variant="permanent" open={open}>
					<Toolbar sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", px: [1] }}>
						<IconButton onClick={toggleDrawer}>
							<ChevronLeftIcon />
						</IconButton>
					</Toolbar>
					<Divider />
					<List component="nav">
						<ListItemButton selected={selectedItem === "advance" ? true : false} onClick={() => setSelectedItem("advance")}>
							<ListItemIcon>
								<AppleIcon />
							</ListItemIcon>
							<ListItemText primary="SearchAds Advance" />
						</ListItemButton>
						<ListItemButton selected={selectedItem === "basic" ? true : false} onClick={() => setSelectedItem("basic")}>
							<ListItemIcon>
								<AppleIcon />
							</ListItemIcon>
							<ListItemText primary="SearchAds Basic" />
						</ListItemButton>
						<ListItemButton selected={selectedItem === "other" ? true : false} onClick={() => setSelectedItem("other")}>
							<ListItemIcon>
								<OtherIcon />
							</ListItemIcon>
							<ListItemText primary="Other" />
						</ListItemButton>
						<ListItemButton selected={selectedItem === "android" ? true : false} onClick={() => setSelectedItem("android")}>
							<ListItemIcon>
								<AdbIcon />
							</ListItemIcon>
							<ListItemText primary="Logs Android" />
						</ListItemButton>
					</List>
				</Drawer>
				<Box
					component="main"
					sx={{
						backgroundColor: (theme) => (theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[900]),
						flexGrow: 1,
						height: "100vh",
						overflow: "auto",
					}}
				>
					<Toolbar />
					{selectedItem === "advance" ? (
						<SearchAdsAdvance />
					) : selectedItem === "basic" ? (
						<SearchAdsBasic />
					) : selectedItem === "other" ? (
						<OtherAds />
					) : (
						<LogAndroid />
					)}
				</Box>
			</Box>
		</ThemeProvider>
	);
}

export default App;
