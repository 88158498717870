import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Chart from "./Chart";
import TrackingTable from "./TrackingTable";
import Conversion from "./Conversion";
import axios from "axios";
import { Button, FormControl, InputLabel, MenuItem, Select, Box, TextField, Backdrop, CircularProgress } from "@mui/material";

const mdTheme = createTheme();

function SearchAdsBasic() {
	const [rows, setRows] = React.useState([]);
	const [selectedValue, setSelectedValue] = React.useState(2);
	const [chartData, setChartData] = React.useState([]);
	const [totalConversion, setTotalConversion] = React.useState(0);
	const [startDate, setStartDate] = React.useState("");
	const [endDate, setEndDate] = React.useState("");
	const [loading, setLoading] = React.useState(false);

	React.useEffect(() => {
		let from, to;
		const now = new Date();

		switch (selectedValue) {
			case 0:
				from = new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime();
				to = now.getTime();
				// from = to - 24 * 60 * 60 * 1000;
				break;
			case 1:
				from = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1).setHours(0, 0, 0, 0);
				to = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1).setHours(23, 59, 59, 999);
				break;
			case 2:
				from = now.getTime() - 3 * 24 * 3600 * 1000;
				to = now.getTime();
				break;
			case 3:
				from = now.getTime() - 7 * 24 * 3600 * 1000;
				to = now.getTime();
				break;
			case 4:
				from = now.getTime() - 30 * 24 * 3600 * 1000;
				to = now.getTime();
				break;
			case 5:
				from = now.getTime() - 90 * 24 * 3600 * 1000;
				to = now.getTime();
				break;
			default:
				const octFirst2022 = new Date(2022, 9, 1);
				from = octFirst2022.getTime();
				to = now.getTime();
				break;
		}

		axios.get(`${window.domain}/api/searchAdsBasicLogs?from=${from}&to=${to}`).then((res) => {
			setRows(res.data.data);
			setChartData(res.data.chart.data);
			let total = 0;
			res.data.data.map((row) => (total = total + row.conversions.length));
			setTotalConversion(total);
		});
	}, [selectedValue]);

	const handleStartDateChange = (event) => {
		setStartDate(event.target.value);
	};

	const handleEndDateChange = (event) => {
		setEndDate(event.target.value);
	};

	const handleFilterDate = async () => {
		setLoading(true);
		try {
			const from = new Date(startDate).setHours(0, 0, 0, 0);
			const to = new Date(endDate).setHours(23, 59, 59, 999);

			const res = await axios.get(`${window.domain}/api/searchAdsBasicLogs?from=${from}&to=${to}`);
			setRows(res.data.data);
			setChartData(res.data.chart.data);
			let total = 0;
			res.data.data.map((row) => (total = total + row.conversions.length));
			setTotalConversion(total);
		} catch (error) {
			console.log(error.message);
		} finally {
			setLoading(false);
		}
	};

	const handleDownloadCSV = async () => {
		try {
			const res = await axios.post(`${window.domain}/api/download-csv-camp`, { data: rows }, { responseType: "blob" });

			const url = window.URL.createObjectURL(new Blob([res.data], { type: "text/csv" }));

			const a = document.createElement("a");
			a.href = url;
			a.download = "Basic Campaign.csv";
			a.style.display = "none";
			document.body.appendChild(a);
			a.click();

			window.URL.revokeObjectURL(url);
			document.body.removeChild(a);
		} catch (error) {
			console.log(error.message);
		}
	};

	return (
		<ThemeProvider theme={mdTheme}>
			<Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
				<Grid container spacing={3}>
					{/* Chart */}
					<Grid item xs={12} md={8} lg={9}>
						<Paper sx={{ p: 2, display: "flex", flexDirection: "column", height: 240 }}>
							<Chart data={chartData} />
						</Paper>
					</Grid>
					{/* Summary */}
					<Grid item xs={12} md={4} lg={3}>
						<Paper sx={{ p: 2, display: "flex", flexDirection: "column", height: 240 }}>
							<Conversion total={totalConversion} />
						</Paper>
					</Grid>
					{/* Select date range */}
					<Grid item xs={12}>
						<FormControl sx={{ m: 1, width: "100%" }}>
							<Grid container spacing={3}>
								<Grid item xs={12} md={10} display="flex">
									<Box>
										<InputLabel id="select-date-range">Select</InputLabel>
										<Select
											labelId="select-date-range"
											id="select-date-range"
											value={selectedValue}
											label="Select"
											size="small"
											autoWidth
											onChange={(event) => setSelectedValue(event.target.value)}
										>
											<MenuItem value={0}>Today</MenuItem>
											<MenuItem value={1}>Yesterday</MenuItem>
											<MenuItem value={2}>Last 3 days</MenuItem>
											<MenuItem value={3}>Last 7 days</MenuItem>
											<MenuItem value={4}>Last 30 days</MenuItem>
											<MenuItem value={5}>Last 90 days</MenuItem>
											<MenuItem value={6}>All times</MenuItem>
										</Select>
									</Box>
									<Box margin="0 1rem" alignItems="center">
										<TextField
											id="start-date"
											label="Start Date"
											type="date"
											size="small"
											value={startDate}
											onChange={handleStartDateChange}
											InputLabelProps={{
												shrink: true,
											}}
										/>
										<TextField
											id="end-date"
											label="End Date"
											type="date"
											size="small"
											value={endDate}
											onChange={handleEndDateChange}
											InputLabelProps={{
												shrink: true,
											}}
										/>
										<Button variant="contained" color="secondary" sx={{ marginLeft: 1 }} onClick={handleFilterDate}>
											Filter
										</Button>
										<Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
											<CircularProgress color="inherit" />
										</Backdrop>
									</Box>
								</Grid>

								<Grid item xs={12} md={2}>
									<Button
										variant="contained"
										sx={{ bgcolor: "green" }}
										onClick={() => {
											handleDownloadCSV();
										}}
									>
										Download CSV
									</Button>
								</Grid>
							</Grid>
						</FormControl>
					</Grid>
					{/* Tracking table */}
					<Grid item xs={12}>
						<Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
							<TrackingTable rows={rows} />
						</Paper>
					</Grid>
				</Grid>
			</Container>
		</ThemeProvider>
	);
}

export default SearchAdsBasic;
